/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
    <div v-if="pages.length > 1" class="paging">
        <ul class="pagination">
            <li class="first">
                <a :class="{ disabled: page === 1 }" href="#" @click.prevent="goFirst($event)"
                    >&laquo;</a
                >
            </li>
            <li class="left">
                <a :class="{ disabled: page === 1 }" href="#" @click.prevent="goBack($event, page)"
                    >&lsaquo;</a
                >
            </li>
            <li v-if="showLeftEllipse" class="left-ellipse">
                <span>...</span>
            </li>
            <li v-for="p in shownPages" :key="p" class="page">
                <div :class="{ active: p === page }">
                    <a href="#" @click.prevent="selectPage($event, p)">{{ p }}</a>
                </div>
            </li>
            <li v-if="showRightEllipse" class="left-ellipse">
                <span>...</span>
            </li>
            <li class="right">
                <a
                    :class="{ disabled: page == pages.length }"
                    href="#"
                    @click.prevent="goForward($event, page)"
                    >&rsaquo;</a
                >
            </li>
            <li class="last">
                <a
                    :class="{ disabled: page == pages.length }"
                    href="#"
                    @click.prevent="goLast($event)"
                    >&raquo;</a
                >
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Paging',
    props: {
        size: {
            required: true,
            type: Number,
            default: 0,
        },
        page: {
            required: true,
            type: Number,
            default: 0,
        },
        total: {
            required: true,
            type: Number,
            default: 0,
        },
        maxPages: {
            required: false,
            type: Number,
            default: 5,
        },
    },
    computed: {
        shownPages() {
            return this.pages.filter((page) => this.showPage(page));
        },
        pages() {
            const totalPages = Math.ceil(this.total / this.size);
            const list = Array.from({ length: totalPages }, (v, k) => k + 1);
            return list;
        },
        showLeftEllipse() {
            return this.page > 3 && this.pages.length > this.maxPages;
        },
        showRightEllipse() {
            return this.page <= this.pages.length - 3 && this.pages.length > this.maxPages;
        },
    },
    methods: {
        goFirst(event) {
            this.$emit('pageSelected', event, 1);
        },
        goBack(event, page) {
            if (page === 1) {
                return;
            }

            this.$emit('pageSelected', event, page - 1);
        },
        selectPage(event, clickedPage) {
            if (clickedPage === this.page) {
                return;
            }

            this.$emit('pageSelected', event, clickedPage);
        },
        goForward(event, page) {
            if (page === this.pages.length) {
                return;
            }

            this.$emit('pageSelected', event, page + 1);
        },
        goLast(event) {
            this.$emit('pageSelected', event, this.pages.length);
        },
        showPage(p) {
            const isMiddlePages = p <= this.page + 2 && p >= this.page - 2;
            const isFirstPages =
                p <= this.maxPages && this.page <= this.maxPages && p >= this.page - 2;
            const isLastPages =
                p > this.pages.length - this.maxPages &&
                this.page > this.pages.length - this.maxPages &&
                p <= this.page + 2;
            return isFirstPages || isMiddlePages || isLastPages;
        },
    },
};
</script>

<style lang="scss">
.pagination {
    text-align: center;
    margin: 20px 0;
    li {
        display: inline-block;
        border-radius: 2px;
        text-align: center;
        vertical-align: top;
        height: 20px;
        line-height: 20px;
        margin: 0px 10px;

        span {
            padding: 0 8px;
        }

        a {
            text-decoration: none;
        }

        &.page {
            div {
                a {
                    width: 100%;
                    display: inline-block;
                    padding: 0 8px;
                }
                margin: 2px;
                color: $pagination-link-color;

                &.active {
                    background: $pagination-link-active-background-color;
                    color: $pagination-link-active-color;
                    font-weight: bold;
                    overflow: hidden;
                    border-radius: 50%;
                    a {
                        cursor: unset;
                        font-size: $font-size-xs;
                    }
                }
                &:not(.active):hover {
                    background: $pagination-link-hover-background-color;
                }
            }
        }

        &.last,
        &.first,
        &.left,
        &.right {
            a {
                margin: 4px;
                padding: 0 4px;
                color: $pagination-arrows-color;
                font-size: 24px;
                &.disabled {
                    color: $pagination-link-disabled-color !important;
                    cursor: unset;
                }
            }
        }
    }
}
</style>
