<template>
    <div class="actions">
        <div v-if="buttonActions.length > 0" class="actions__buttons">
            <IconButton
                v-for="(action, idx) in buttonActions"
                :key="`action-${idx}`"
                v-tooltip="action.label"
                :icon="action.icon"
                :disabled="action.disabled"
                class="actions__button"
                :style="{ fontSize: action.iconSize || null }"
                @click="$emit('action', action.action)"
            />
        </div>

        <Menu button-icon="actions-menu" class="actions__menu" :disabled="menuActions.length === 0">
            <DropDownItem
                v-for="(menuAction, menuActionIdx) in menuActions"
                :key="`menu-action-${menuActionIdx}`"
                :icon="menuAction.icon"
                @click.stop="$emit('action', menuAction.action)"
            >
                {{ menuAction.label }}
            </DropDownItem>
        </Menu>
    </div>
</template>

<script>
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import IconButton from '@/components/ui/IconButton';
import Menu from '@/components/ui/Menu';

export default {
    name: 'TableRowActions',
    components: {
        DropDownItem,
        IconButton,
        Menu,
    },
    props: {
        buttonActions: {
            type: Array,
            default: () => [],
        },
        menuActions: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
.actions__buttons {
    opacity: 0;
    transition: opacity 100ms ease-in-out;

    tr:hover & {
        opacity: 1;
    }
}
</style>

<style scoped lang="scss">
.actions {
    padding: 0 4px;
    display: flex;
    justify-content: flex-end;

    &__buttons {
        position: relative;
        display: flex;
        padding-right: 6px;
        margin-right: 6px;

        @include media-query(medium) {
            margin-right: 10px;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            height: 20px;
            width: 1px;
            background-color: $table-divider-color;
            @include center(y);
        }
    }

    &__button {
        margin: 0 2px;
        font-size: 14px;
        color: $table-icon-color;
        flex-shrink: 0;

        @include media-query(medium) {
            margin: 0 6px;
        }
    }
}
</style>
