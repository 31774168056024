<template>
    <div class="audience-list">
        <router-view :nav-is-opened="navIsOpened"></router-view>

        <FullPageLoadingSpinner :show="$wait.is(LoadingFlag.AudienceGet)" />

        <PageHeading :breadcrumbs="breadcrumbs" title="Audiences" />

        <div class="audience-list__search m-b-2">
            <Input
                id="search"
                ref="searchTerm"
                v-model="searchTerm"
                type="text"
                name="search"
                label="Search audiences"
                class="search w-25"
                :has-margin-bottom="false"
                :show-clear-icon="true"
                placeholder="Search for an audience here"
                default-icon="magnifying-glass"
                @input="searchAudiences"
            />
        </div>

        <Datatable
            class="audience-datatable"
            :data="audienceList"
            :clickable-rows="true"
            :columns="tableColumns"
            @rowClick="onClickRow"
        >
            <template v-slot:exported="slotProps">
                <Badge
                    v-if="slotProps.item.exported === AudienceExportStatus.exported"
                    type="success"
                >
                    Exported
                </Badge>
                <Badge
                    v-else-if="slotProps.item.exported === AudienceExportStatus.in_progress"
                    type="progress"
                >
                    In Progress
                </Badge>
                <Badge
                    v-else-if="slotProps.item.exported === AudienceExportStatus.error"
                    type="error"
                >
                    Error
                </Badge>
                <Badge
                    v-else-if="slotProps.item.exported === AudienceExportStatus.no_users"
                    type="neutral"
                >
                    Nothing To Export
                </Badge>
                <Badge v-else type="neutral">Not Exported</Badge>
            </template>
            <template v-slot:action="slotProps">
                <TableRowActions
                    :menu-actions="getMenuActions(slotProps)"
                    @action="onRowAction($event, slotProps.item.id)"
                />
            </template>
        </Datatable>

        <Paging
            :total="audienceList.total"
            :size="audienceList.size"
            :page="audienceList.page"
            @pageSelected="selectPage"
        />

        <Stepper
            show-next-button
            next-button-help=""
            next-button-text="New Audience"
            :is-loading="false"
            @nextButtonOnClick="confirmButtonClick($event)"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Datatable from '../components/Datatable.vue';
import Paging from '../components/Paging';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner';
import { AudienceExportStatus } from '@/store/enums/audienceExport.enum.ts';
import Input from '@/components/forms/Input';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import Badge from '@/components/ui/Badge';
import Stepper from '@/components/ui/Stepper';
import PageHeading from '@/components/ui/PageHeading';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import TableRowActions from '@/components/TableRowActions';

export default {
    name: 'AudienceList',
    components: {
        Datatable,
        Paging,
        FullPageLoadingSpinner,
        Input,
        PageHeading,
        Badge,
        Stepper,
        TableRowActions,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbs: [],
            LoadingFlag,
            searchTerm: '',
            includeInactive: false,
            tableColumns: [
                {
                    value: 'name',
                    header: 'AUDIENCE NAME',
                    type: 'text',
                    width: '30%',
                },
                {
                    value: 'createdAt',
                    header: 'CREATED ON',
                    type: 'text',
                    width: '20%',
                },
                {
                    value: 'updatedAt',
                    header: 'MODIFIED ON',
                    type: 'text',
                    width: '20%',
                },
                {
                    value: 'exported',
                    header: 'EXPORT STATUS',
                    type: 'slot',
                    width: '10%',
                },
                {
                    value: 'action',
                    header: '',
                    type: 'slot',
                    width: '20%',
                    class: 'table-row-actions',
                },
            ],
            AudienceExportStatus,
        };
    },
    computed: {
        ...mapState('audienceList', ['audienceList']),
    },
    async created() {
        this.setBreadcrumbs();
        await this.getAudiences({
            q: this.searchTerm,
            includeInactive: this.includeInactive,
            page: this.audienceList.page,
        });
    },
    mounted() {
        document.getElementById('search').focus();
    },
    methods: {
        ...mapActions('audienceList', ['getAudiences', 'deleteAudience', 'exportAudience']),
        onRowAction(action, audienceId) {
            if (action === 'export-audience') {
                this.exportAudienceList(audienceId);
            }
            if (action === 'delete-audience') {
                this.deleteAudienceFromList(audienceId);
            }
        },
        getMenuActions(slotProps) {
            const actions = [];
            if (slotProps.item.exported !== AudienceExportStatus.in_progress) {
                actions.push({
                    icon: 'export-audience',
                    action: 'export-audience',
                    label: 'Export Audience',
                });
            }
            actions.push({
                icon: 'delete-full',
                action: 'delete-audience',
                label: 'Delete Audience',
            });
            return actions;
        },
        async selectPage(event, page) {
            await this.getAudiences({ q: this.searchTerm, page });
        },
        async searchAudiences() {
            await this.getAudiences({
                q: this.searchTerm,
                includeInactive: this.includeInactive,
                page: this.audienceList.page,
            });
        },
        confirmButtonClick() {
            this.$router.push({ name: 'create-audience' });
        },
        onClickRow(event, item) {
            if (item.id) {
                this.$router.push({ name: 'edit-audience', params: { audienceId: item.id } });
            }
        },
        async deleteAudienceFromList(id) {
            await this.deleteAudience(id);
            await this.getAudiences({
                q: this.searchTerm,
                includeInactive: this.includeInactive,
                page: this.audienceList.page,
            });
        },
        async exportAudienceList(id) {
            await this.exportAudience(id);
            await this.getAudiences({
                q: this.searchTerm,
                includeInactive: this.includeInactive,
                page: this.audienceList.page,
            });
        },
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('Audiences', { name: 'list-audiences' })];
        },
    },
};
</script>

<style lang="scss" scoped>
.audience-list {
    &__search {
        display: flex;
        align-items: center;

        & > div {
            margin-right: 1em;
        }
    }
}
</style>
